import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import constants from "../config/constants";

const initialState = {
  token: localStorage.getItem("token") || null,
  isAuthenticated: localStorage.getItem("token") ? true : false,
  loading: false,
  error: null,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
};

// Define a thunk to handle asynchronous login operation
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password, project }, { rejectWithValue, dispatch }) => {
    try {
      console.log("credentials", email, password, project);
      const response = await axios.post(`${constants.baseURL}loginWeb`, {
        email,
        password,
        project,
      });
      dispatch(setToken(response.data.token));
      dispatch(setUser(response.data.user));

      return response.data.token;
    } catch (error) {
      alert(error.response.data.msg);
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

// Create a slice for authentication
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem("token", action.payload);
    },
    setUser(state, action) {
      console.log("useraction", action.payload);
      state.user = action.payload;

      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logout(state) {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// Export actions
export const { logout, setToken, setUser } = authSlice.actions;

// Export reducer
export default authSlice.reducer;
