import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

import SidebarLayout from "./layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import PrivateRoute from "./components/PrivateRoute";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications

const Profile = Loader(
  lazy(() => import("src/contents/applications/WebUsers"))
);

const BroadcastUsers = Loader(
  lazy(() => import("src/contents/applications/Broadcast"))
);

const AppUsers = Loader(
  lazy(() => import("src/contents/applications/Appusers"))
);

const Projects = Loader(
  lazy(() => import("src/contents/applications/Projects"))
);

const VideoGuide = Loader(
  lazy(() => import("src/contents/applications/VideoGuide"))
);

const ContactUs = Loader(
  lazy(() => import("src/contents/applications/ContactUs"))
);

const Alerts = Loader(lazy(() => import("src/contents/applications/Alerts")));

const Notifications = Loader(
  lazy(() => import("src/contents/applications/Notifications"))
);

const Reports = Loader(lazy(() => import("src/contents/applications/Reports")));

const Locations = Loader(
  lazy(() => import("src/contents/applications/Geofencing"))
);

const LocationMap = Loader(
  lazy(() => import("src/contents/applications/Geofencing/LocationMap"))
);

const SignIn = Loader(lazy(() => import("src/contents/applications/SignIn")));

const Privacy = Loader(lazy(() => import("src/contents/applications/Privacy")));

const Overview = Loader(lazy(() => import("src/contents/overview")));

const routes = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <Overview />,
      },
    ],
  },
  {
    path: "/projects",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "/appusers",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <AppUsers />,
      },
    ],
  },
  {
    path: "/webusers",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <Profile />,
      },
    ],
  },
  {
    path: "/broadcast",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <BroadcastUsers />,
      },
    ],
  },
  {
    path: "/alerts",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <Alerts />,
      },
    ],
  },
  {
    path: "/notifications",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "/reports",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <Reports />,
      },
    ],
  },
  {
    path: "/locations",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <Locations />,
      },
    ],
  },
  {
    path: "/locations/add",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <LocationMap />,
      },
    ],
  },
  {
    path: "/locations/edit/:id",
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: "",
        element: <LocationMap />,
      },
    ],
  },
  {
    path: "/contactus",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <ContactUs />,
      },
    ],
  },
  {
    path: "/video_guide",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <VideoGuide />,
      },
    ],
  },
  {
    path: "/signin",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/privacy",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <Privacy />,
      },
    ],
  },
];

export default routes;
